import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/admin/';




function signIn(data){
    return axios.post(BaseUrl + "sign-in",data);
}


function getOwnDetail(){
    return axios.get(BaseUrl+ 'own-detail');
}

function getSupervisorProfiles(){
    return axios.get(BaseUrl+ 'profiles');
}

function createSupervisor(data){
    return axios.post(BaseUrl,data);

}

function getAdmin(options){
    if(!options.search){
        options.search = ''
    }
    return axios.get(BaseUrl+ '?page='+options.page+'&size='+options.itemsPerPage+'&search='+options.search);
}

function changePassword(data){
    return axios.put(BaseUrl + "change-password",data);
}









export default {
    signIn,
    getOwnDetail,
    getSupervisorProfiles,
    createSupervisor,
    getAdmin,
    changePassword,
   
}