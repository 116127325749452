

const state = {
 
  
  
   
 }
 
 
 const mutations = {
    
 }
 
 const actions = {
 
 }
 
 const getters = {
  
 }
 
 // export this module.
 export default {
     state,
     mutations,
     getters,
     actions
 }
 