import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/expert-profile/';


function uploadEducation(data){
    return axios.post(BaseUrl+'education',data);
}

function updateEducation(data){
    return axios.put(BaseUrl+'education',data);
}

function deleteEducation(expertId, id){
    return axios.delete(BaseUrl+ 'education/'+expertId+'/'+id)
}

function uploadExperience(data){
    return axios.post(BaseUrl+'experience',data);
}

function updateExperience(data){
    return axios.put(BaseUrl+'experience',data);
}

function deleteExperience(expertId, id){
    return axios.delete(BaseUrl+ 'experience/'+expertId+'/'+id)
}











export default {
    uploadEducation,
    updateEducation,
    deleteEducation,
    uploadExperience,
    updateExperience,
    deleteExperience
   
}