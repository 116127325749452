import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/report/';


function getQuestionAnswer(page=1,size=10){
 return axios.get(BaseUrl+ 'question-answer?page='+page+'&size='+size);
}

function getConversation(page=1,size=10){
    return axios.get(BaseUrl+ 'conversation?page='+page+'&size='+size);
}

function getMostMessages(page=1,size=10){
    return axios.get(BaseUrl+ 'conversation/most-messages?page='+page+'&size='+size);
}








export default {
  getQuestionAnswer,
getConversation,
getMostMessages,
   
}