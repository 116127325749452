import { render, staticRenderFns } from "./AppFilter.vue?vue&type=template&id=18fc23a4&scoped=true&"
import script from "./AppFilter.vue?vue&type=script&lang=js&"
export * from "./AppFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fc23a4",
  null
  
)

export default component.exports