import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  //this is just an example. You will have to find a better or
  // centralised way to handle you localstorage data handling
  if (localStorage.getItem("loggedInUser")) isAuthenticated = true;
  else isAuthenticated = false;

  if (isAuthenticated) {
    next(); // allow to enter route
  } else {
    next("/auth/sign-in"); // go to '/login';
  }
}

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("@/views/dashboard/Index"),
      beforeEnter: guardMyroute,
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "",
          component: () => import("@/views/dashboard/Dashboard"),
        },
        {
          name: "Category",
          path: "category",
          component: () => import("@/views/dashboard/category/CreateCategory"),
        },
        {
          name: "Design",
          path: "design",
          component: () => import("@/views/dashboard/Design/Design"),
        },
        {
          name: "Construction",
          path: "construction",
          component: () =>
            import("@/views/dashboard/construction/ConstructionList"),
        },
        {
          name: "Construction",
          path: "construction/:id",
          component: () =>
            import("@/views/dashboard/construction/ConstructionDetails"),
        },
        {
          name: "Admins",
          path: "/admin",
          component: () => import("@/views/dashboard/Users/AdminList"),
        },
        {
          name: "Create Admin",
          path: "/admin/create",
          component: () => import("@/views/dashboard/Users/CreateAdmin"),
        },
        {
          name: "Expert",
          path: "/expert",
          component: () => import("@/views/dashboard/Users/Expert/ExpertList"),
        },
        {
          name: "Create Expert",
          path: "/expert/create",
          component: () =>
            import("@/views/dashboard/Users/Expert/CreateExpert"),
        },
        {
          name: "Edit Expert",
          path: "/expert/:id",
          component: () => import("@/views/dashboard/Users/Expert/EditExpert"),
        },
        {
          name: "Moderator",
          path: "/moderator",
          component: () => import("@/views/dashboard/Users/ModeratorList"),
        },
        {
          name: "Create Moderator",
          path: "/moderator/create",
          component: () => import("@/views/dashboard/Users/CreateModerator"),
        },

        {
          name: "Customers",
          path: "/customers",
          component: () => import("@/views/dashboard/Users/CustomerList"),
        },
        {
          name: "Reports",
          path: "/reports",
          component: () => import("@/views/dashboard/Reports/Report"),
        },
        {
          name: "Messages",
          path: "message",
          component: () => import("@/views/dashboard/Messages.vue"),
        },
        {
          name: "Messages",
          path: "message/:id",
          component: () => import("@/views/dashboard/Messages.vue"),
        },
        {
          name: "Change Password",
          path: "change-password",
          component: () => import("@/views/dashboard/ChangePassword"),
        },
        {
          name: "On Site Consultation",
          path: "on-site-consultation",
          component: () =>
            import(
              "@/views/dashboard/OnSiteConsultation/OnSiteConsultationList"
            ),
        },
        {
          name: "On Site Consultation Details",
          path: "on-site-consultation/:id",
          component: () =>
            import(
              "@/views/dashboard/OnSiteConsultation/OnSiteConsultationDetails"
            ),
        },
        {
          name: "Video Conference List",
          path: "video-conference",
          component: () =>
            import("@/views/dashboard/VideoConference/VideoConferenceList"),
        },
        {
          name: "Video Conference Details",
          path: "video-conference/:id",
          component: () =>
            import("@/views/dashboard/VideoConference/VideoConferenceDetails"),
        },
        {
          name: "Menu Settings",
          path: "menu-settings",
          component: () =>
            import("@/views/dashboard/MenuSettings/MenuSettings"),
        },
        {
          name: "App Settings",
          path: "app-settings",
          component: () => import("@/views/dashboard/AppSettings/AppSettings"),
        },
        {
          name: "Global Notification",
          path: "global-notification",
          component: () =>
            import("@/views/dashboard/GlobalNotification/GlobalNotification"),
        },
        // Pages
        {
          name: "User Profile",
          path: "pages/user",
          component: () => import("@/views/dashboard/pages/UserProfile"),
        },
        {
          name: "Notifications",
          path: "components/notifications",
          component: () => import("@/views/dashboard/component/Notifications"),
        },
        {
          name: "Icons",
          path: "components/icons",
          component: () => import("@/views/dashboard/component/Icons"),
        },
        {
          name: "Typography",
          path: "components/typography",
          component: () => import("@/views/dashboard/component/Typography"),
        },
        // Tables
        {
          name: "Regular Tables",
          path: "tables/regular-tables",
          component: () => import("@/views/dashboard/tables/RegularTables"),
        },
        // Maps
        {
          name: "Google Maps",
          path: "maps/google-maps",
          component: () => import("@/views/dashboard/maps/GoogleMaps"),
        },
        // Upgrade
        {
          name: "Upgrade",
          path: "upgrade",
          component: () => import("@/views/dashboard/Upgrade"),
        },
      ],
    },
    {
      path: "/auth",
      component: () => import("@/views/Auth/Index.vue"),
      children: [
        {
          name: "Sign In",
          path: "sign-in",
          component: () => import("@/views/Auth/Signin"),
        },
        {
          name: "Forgot Password",
          path: "forgot-password",
          component: () => import("@/views/Auth/ForgotPassword"),
        },
        // {
        //   name: 'Sign Up',
        //   path: 'signUp',
        //   component: () => import('@/views/Auth/Signup'),
        // },
        // {
        //   name: 'Forgot Password',
        //   path: 'forgot-password',
        //   component: () => import('@/views/Auth/ForgotPassword'),
        // }
      ],
    },
    { path: "*", component: () => import("@/views/PageNotFound") },
  ],
});
