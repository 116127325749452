import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/';

function getVideoConference(page=1,size=10){
    return axios.get(BaseUrl+'video-conference/supervisor?page='+page+'&size='+size);
}

function getVideoConferenceById(id){
    return axios.get(BaseUrl+'video-conference/supervisor/'+ id)
}

function approveConference(data){
    return axios.put(BaseUrl+'video-conference/'+'approve',data);
}


function approveConferenceCallCompletion(id){
    return axios.put(BaseUrl+ 'video-conference/called/approve/'+id)
}


function getOnSiteConsultation(page=1,size=10){
    return axios.get(BaseUrl+'on-site-consultation/supervisor?page='+page+'&size='+size);
}

function getOnSiteConsultationById(id){
    return axios.get(BaseUrl+'on-site-consultation/supervisor/'+ id)
}

function approveOnSiteConsultation(data){
    return axios.put(BaseUrl+'on-site-consultation/'+'approve',data);
}

function onSiteConsultationCompleted(id){
    return axios.put(BaseUrl+'on-site-consultation/'+ 'visited/approve/'+id);
}

function getConstructionList(options){
    if(!options.search){
        options.search = ''
    }
    return axios.get(BaseUrl+ 'construction?page='+options.page+'&size='+options.itemsPerPage+'&search='+options.search+'&isDeleted='+options.isDeleted);
}

function getConstructionById(id){
    return axios.get(BaseUrl+ 'construction/'+id);
}

function approveConstruction(id){
    return axios.put(BaseUrl+ 'construction/approve/'+id)
}
function disapproveConstruction(data){
    return axios.put(BaseUrl+ 'construction/disapprove',data)
}

function notifyConstruction(data){
    return axios.put(BaseUrl+'construction/notify', data);
}

function getConstructionNotificationList(id, page=1,size=10){
    return axios.get(BaseUrl+ 'construction/notification/'+id+'?page='+page+'&size='+size)
}

function getStyle(){
    return axios.get(BaseUrl+'menu/style');
}

function createStyle(data){
    return axios.post(BaseUrl+'menu/style',data);
}

function deleteStyle(id){
    return axios.delete(BaseUrl+'menu/style/'+id);
}

function getProject(){
    return axios.get(BaseUrl+'menu/project');
}

function createProject(data){
    return axios.post(BaseUrl+'menu/project',data);
}

function deleteProject(id){
    return axios.delete(BaseUrl+'menu/project/'+id);
}

function getPlatform(){
    return axios.get(BaseUrl+'menu/platform');
}

function createPlatform(data){
    return axios.post(BaseUrl+'menu/platform',data);
}

function deletePlatform(id){
    return axios.delete(BaseUrl+'menu/platform/'+id);
}

function getSettings(){
    return axios.get(BaseUrl + 'setting');
}

function updateSettings(data){
    return axios.put(BaseUrl+ 'setting',data)
}

function getGlobalNotification(){
    return axios.get(BaseUrl+'global-notify');
}

function postGlobalNotification(data){
    return axios.post(BaseUrl+'global-notify',data);
}


export default {   
    getVideoConference,
    getVideoConferenceById,
    approveConference,
    approveConferenceCallCompletion,
    getOnSiteConsultation,
getOnSiteConsultationById,
approveOnSiteConsultation,
onSiteConsultationCompleted,
getConstructionList,
getConstructionById,
approveConstruction,
disapproveConstruction,
notifyConstruction,
getConstructionNotificationList,

getStyle,
createStyle,
deleteStyle,
getProject,
createProject,
deleteProject,
getPlatform,
createPlatform,
deletePlatform,

getSettings,
updateSettings,
getGlobalNotification,
postGlobalNotification

   
}