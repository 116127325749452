<template>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field append-icon="mdi-magnify" label="Search" single-line hide-details
                @change="searchEvent($event)"></v-text-field>
            </v-col>
            <v-col md="4">
              <v-select label="Filter Records By" :items="['All', 'true', 'false']" hide-details='true' @change="isDeletedChangeEvent($event)"></v-select>
            </v-col>
          </v-row>
        </v-container>
</template>


<script>
export default {
    data(){
        return{
            options: {
                search: ''
            }
        }
    },
    methods:{
        searchEvent(event){
            this.options.search = event;
            this.$emit('filter-change', this.options);
        },
        isDeletedChangeEvent(event){
            this.options.isDeleted = event;
            this.$emit('filter-change', this.options)
        }
    }
}

</script>


<style scoped>
</style>