import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/moderator/';

function getModeratorList(options){
    if(!options.search){
        options.search = ''
    }
    return axios.get(BaseUrl+ '?page='+options.page+'&size='+options.itemsPerPage+'&search='+options.search);
}

function createModerator(data){
    return axios.post(BaseUrl,data);
}


export default {
   
    getModeratorList,
    createModerator
   
}