import axios from "axios";

let BaseUrl = process.env.VUE_APP_FILE_URL +'/admin';
// let BaseUrl  = "https://message.hyphen.noodle-code.com/file/expert";


function uploadFile(data){
    return axios.post(BaseUrl,data);
}







export default {
uploadFile
   
}