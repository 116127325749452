import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/customer/';

function getCustomers(page=1,size=10){
    return axios.get(BaseUrl+'?page='+page+'&size='+size);
}

function blockCustomer(id){
    return axios.put(BaseUrl+ 'block/'+ id)
}

function unblockCustomer(id){
    return axios.put(BaseUrl+ 'unblock/'+ id)
}

export default {
    getCustomers,
    blockCustomer,
    unblockCustomer
}