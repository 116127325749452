import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/';

function createCategory(data){
    return axios.post(BaseUrl+'category',data);
}
function getCategory(){
    return axios.get(BaseUrl+'category');
}
function getCategoryById(id){
    return axios.get(BaseUrl+'category'+'/'+id);
}
function updateCategory(data){
    return axios.put(BaseUrl+'category',data);
}
function deleteCategory(id){
    return axios.delete(BaseUrl+'category'+'/'+id);
}

function createSubCategory(data){
    return axios.post(BaseUrl+'sub-category',data);
}

function getSubCategory(){
    return axios.get(BaseUrl+'sub-category');
}

function getSubCategoryById(id){
    return axios.get(BaseUrl+'sub-category'+'/'+id);
}
function updateSubCategory(data){
    return axios.put(BaseUrl+'sub-category'+'/',data);
}
function deleteSubCategory(id){
    return axios.delete(BaseUrl+'sub-category'+'/'+id);
}

function getSubCategoriesByCategory(id){
    return axios.get(BaseUrl+ 'sub-category/category/'+id)
}

export default {
    createCategory,
    getCategory,
    getCategoryById,
    updateCategory,
    deleteCategory,
    createSubCategory,
    getSubCategory,
    getSubCategoryById,
    updateSubCategory,
    deleteSubCategory,
    getSubCategoriesByCategory
   }
