import axios from "axios";

let BaseUrl = process.env.VUE_APP_API_URL + '/expert/';


function getExpertList(){
    return axios.get(BaseUrl);
}

function getExpertById(id){
    return axios.get(BaseUrl+id)
}

function createExpert(data){
    return axios.post(BaseUrl,data);
}

function editExpert(data){
return axios.put(BaseUrl,data);
}

function getDepartment(){
    return axios.get(BaseUrl+'department');
}







export default {
getExpertList,
createExpert,
getDepartment,
getExpertById,
editExpert
   
}